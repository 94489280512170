import { useState, useEffect, forwardRef, useImperativeHandle } from "react";

const UncontrolledInput = forwardRef(
  ({ initialValue, savedValue, onChange, inputKey }, ref) => {
    const [value, setValue] = useState(savedValue || initialValue);

    useEffect(() => {
      setValue(savedValue || initialValue);
    }, [initialValue, savedValue]);

    const handleChange = (e) => {
      setValue(e.target.value);
      if (onChange) {
        onChange(inputKey, e.target.value);
      }
    };

    useImperativeHandle(ref, () => ({
      getValue: () => value,
    }));

    return (
      <input
        className="report__page-header report__arrythmia-header"
        value={value}
        onChange={handleChange}
        id={inputKey}
        name={inputKey}
      />
    );
  }
);

export default UncontrolledInput;
