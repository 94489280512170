import "./App.css";
import { Report,ReportNew } from "./components";


function App() {
  return (
    <div>
      <Report />
      {/* <ReportNew/> */}
    </div>
  );
}

export default App;
